import BxComponents from "./components";

const VueBx = {
  install(Vue) {
    Object.values(BxComponents).forEach((BxComponent) => {
      Vue.component(BxComponent.name, BxComponent);
    });
  },
};

export default VueBx;
