import Vue from "vue";
import { formatDate } from "@/utils";

const state = {
  printers: [],
  printersType: [],
};

const mutations = {
  SET_PRINTERS: (state, payload) => {
    state.printers = payload;
  },
  SET_PRINTERS_TYPE: (state, payload) => {
    state.printersType = payload;
  },
};

const actions = {
  fetchPrinters({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.printers
        .getPrinters(params)
        .then(({ data }) => {
          commit("SET_PRINTERS", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  fetchPrintersType({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.printers
        .getPrintersType()
        .then(({ data }) => {
          commit("SET_PRINTERS_TYPE", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  addPrinter(_, params) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.printers
        .addPrinter(params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  editPrinter(_, { params, id }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.printers
        .editPrinter(params, id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  deletePrinter(_, id) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.printers
        .deletePrinter(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
};

const getters = {
  getPrinters: (state) => ({
    ...state.printers,
    results: state.printers.results?.map((x) => ({
      ...x,
      formattedDate: formatDate(x.creation_date),
    })),
  }),
  getPrintersType: (state) => state.printersType,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
