<template>
  <div :class="rootClasses">
    <span :class="[_titleColor, 'text-center']">{{ title }}</span>
    <span :class="[_valueColor, 'text-center', 'text-h4']">{{ value }}</span>
  </div>
</template>

<script>
export default {
  name: "box-regular",
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    titleColor: {
      type: [String],
      default: "white",
    },
    valueColor: {
      type: [String],
      default: "white",
    },
  },
  data() {
    return {
      rootClasses: ["d-flex", "flex-column", "align-center"],
    };
  },
  computed: {
    _titleColor() {
      return this.titleColor + "--text";
    },
    _valueColor() {
      return this.valueColor + "--text";
    },
  },
};
</script>

<style></style>
