import Vue from "vue";

const state = {
  token: null,
  userData: null,
};

const mutations = {
  LOGIN: (state, payload) => {
    state.token = payload;
  },
  LOGOUT: (state) => {
    state.token = null;
    localStorage.clear();
  },
  SET_USER_DATA: (state, payload) => {
    state.userData = payload;
  },
};

const actions = {
  login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.auth
        .login(payload)
        .then(({ data }) => {
          commit("LOGIN", data.token);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
  logout({ commit }, list) {
    commit("LOGOUT", list);
  },
  getUserDetail({ commit }, username) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.auth
        .getUserDetail(username)
        .then(({ data }) => {
          commit("SET_USER_DATA", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
};

const getters = {
  authenticated: (state) => {
    return state.authtoken !== null;
  },
  userdata: (state) => state.userData,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
