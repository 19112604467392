import _axios from "axios";
import store from "../store";
export const API_BASE = process.env.VUE_APP_API_BASE;

export const axios = _axios.create({
  baseURL: API_BASE,
  headers: {
    Accept: "application/json",
  },
});

export default () => {
  axios.interceptors.request.use(async (config) => {
    if (store.state.auth.token) {
      config.headers["Authorization"] = "Token " + store.state.auth.token;
    }
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return {
    auth: {
      login: (params) => axios.post("/printer/authtoken/", params),
      getUserDetail: (username) => axios.get(`printer/user/${username}`),
    },
    dashboard: {
      getPrinterTable: (params) =>
        axios.get(`/printer/print_order/`, { params }),
    },
    printers: {
      getPrintersType: () => axios.get(`/printer/printer_type/`),
      getPrinters: (params) => axios.get(`/printer/printer/`, { params }),
      deletePrinter: (id) => axios.delete(`/printer/printer/${id}/`),
      addPrinter: (params) => axios.post(`/printer/printer/`, params),
      editPrinter: (params, id) => axios.put(`/printer/printer/${id}/`, params),
    },
    order_print: {
      getAllResponsible: (params) =>
        axios.get(`printer/company/?type_company=THIRDPARTY`, params),
      getAllPrinters: (params) => axios.get(`printer/printer/`, params),
      printOrder: (params) => axios.post("/printer/print/", params),
      printThirdpartyOrder: (order_id, params) =>
        axios.put(`/printer/print_order/${order_id}/`, params),
      downloadInvoicedCsv: (order_id) =>
        axios.get(`printer/print_order/${order_id}/download_invoiced_csv/`),
    },
  };
};
