import Vue from "vue";

const state = {
  responsibles: [],
};

const mutations = {
  SET_ALL_RESPONSIBLE: (state, payload) => {
    state.responsibles = payload;
  },
};

const actions = {
  fetchAllResponsible({ commit }, qry = "") {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.order_print
        .getAllResponsible(qry)
        .then(({ data }) => {
          commit("SET_ALL_RESPONSIBLE", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
  printOrder(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.order_print
        .printOrder(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
  printThirdpartyOrder(_, { order_id, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.order_print
        .printThirdpartyOrder(order_id, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
  downloadInvoicedCsv(_, order_id) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.order_print
        .downloadInvoicedCsv(order_id)
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `order_${order_id}_download.csv`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();

          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
};

const getters = {
  getAllResponsible: (state, getters, rootState) => {
    const isAdmin = rootState.auth.userData.role === "ADMIN";
    if (isAdmin) {
      return [
        { id: null, name: "All", type_company: "ALL" },
        { id: 1, name: "Bionix", type_company: "ADMIN" },
        ...state.responsibles,
      ];
    } else {
      return [
        { id: null, name: "All", type_company: "ALL" },
        ...state.responsibles,
      ];
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
