export default [
  {
    path: "dashboard",
    name: "dashboard",
    component: () =>
      import(
        /* webpackChunkName: "admin-dashboard" */ "@/views/admin/Dashboard.vue"
      ),
    meta: {
      title: "Bionix",
      auth: true,
    },
  },
  {
    path: "order-print",
    name: "order-print",
    component: () =>
      import(
        /* webpackChunkName: "admin-dashboard" */ "@/views/admin/OrderPrint.vue"
      ),
    meta: {
      title: "Printing Orders",
      auth: true,
    },
  },
  {
    path: "printers",
    name: "printers",
    component: () =>
      import(
        /* webpackChunkName: "admin-dashboard" */ "@/views/admin/Printers.vue"
      ),
    meta: {
      title: "Printers",
      auth: true,
    },
  },
];
